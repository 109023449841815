import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _ from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import PaginatedPrintbaseList from '../layouts/PaginatedPrintbaseList';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const sortByInputs = [
    {
        value: 'print_exp_id',
        label: 'Experiment ID'
    },
    {
        value: 'phd_id',
        label: 'PhD ID'
    },
    {
        value: 'lab_id',
        label: 'Lab ID'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'bioink_id',
        label: 'BioInk ID'
    },
];

export default function PrintbaseEvaluation() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allPrintbaseButtonVariant, setAllPrintbaseButtonVariant ] = useState("outlined");
    const [ myPrintbaseButtonVariant, setMyPrintbaseButtonVariant ] = useState("outlined");
    const [ allPrintbaseButtonSize, setAllPrintbaseButtonSize ] = useState();
    const [ myPrintbaseButtonSize, setMyPrintbaseButtonSize ] = useState();

    const [ comparePrintbases, setComparePrintbases ] = useState([]);
    const [ compareButton, setCompareButton ] = useState(false);
    const [ compareButtonText, setCompareButtonText ] = useState("Compare this...");

    const [ allPrintbases, setAllPrintbases ] = useState([]);
    const [ printbases, setPrintbases ] = useState([]);
    const [ printbasesLoaded, setPrintbasesLoaded ] = useState(false);
    let searchKey = "";
    const [ sortBy, setSortBy ] = useState("");
  
    const [ loading, setLoading ] = useState(false);
    const paginationRef = useRef();

    useEffect(() => {
        loadAllPrintbases();
    }, []);

    const loadAllPrintbases = async () => {
        try {
            setLoading(true);
            const printbasesRes = await axiosClient.get("printbase/getAll");
            setPrintbases(printbasesRes.data);
            setAllPrintbases(printbasesRes.data);
            setPrintbasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setAllPrintbaseButtonVariant("contained");
        setAllPrintbaseButtonSize("large");
        setMyPrintbaseButtonVariant("outlined");
        setMyPrintbaseButtonSize("medium");
    };

    const loadMyPrintbases = async () => {
        try {
            setLoading(true);
            const printbasesRes = await axiosClient.get("printbase/getMyPrintbases");
            setPrintbases(printbasesRes.data);
            setAllPrintbases(printbasesRes.data);
            setPrintbasesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setMyPrintbaseButtonVariant("contained");
        setMyPrintbaseButtonSize("large");
        setAllPrintbaseButtonVariant("outlined");
        setAllPrintbaseButtonSize("medium");
    }

    const handleListItemClick = (e, printExpId) => {
        localStorage.setItem("print_exp_id", printExpId);
        history.push("/printbaseExperiment");
    };

    let compareRef = useRef();

    const handleCompareClick = (e, pId) => {
        comparePrintbases.push(pId);
        setCompareButtonText("..With this")
        if (comparePrintbases.length === 2) {
            goToCompare();
        }
    };

    const goToCompare = () => {
        localStorage.setItem("comparePrintbase1", comparePrintbases[0]);
        localStorage.setItem("comparePrintbase2", comparePrintbases[1]);
        history.push("/printbaseCompare");
    };

    const filterPrintbases = () => {
        const results = [];
        allPrintbases.forEach(printbase => {
            if (printbase.print_exp_id.includes(searchKey) || printbase.phd_id.includes(searchKey) || printbase.lab_id.includes(searchKey)
                || printbase.date.includes(searchKey) || printbase.bioink_id.includes(searchKey)) {
                results.push(printbase);
            }
        });
        setPrintbases(results);
    };
    
    const sortPrintbases = (sortKey) => {
        setPrintbases(_.sortBy(printbases, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Printbase Data Evaluation
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    Here you can view the uploaded printbase experiment data.
                    </Typography>
                    <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                        <Button variant={allPrintbaseButtonVariant} color="primary" size={allPrintbaseButtonSize} onClick={() => loadAllPrintbases()}>
                            All Printbase Experiments
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button variant={myPrintbaseButtonVariant} color="primary" size={myPrintbaseButtonSize} onClick={() => loadMyPrintbases()}>
                            My Printbase Experiments
                        </Button>
                        </Grid>
                    </Grid>
                    </div>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(printbasesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="printbase_search"
                                    label="Search Printbase"
                                    name="printbase_search"
                                    onChange={(e) => {searchKey=e.target.value; filterPrintbases();}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="printbase_sort"
                                    name="printbase_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortPrintbases(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(printbases.length < 1) ? (<h5>No results</h5>) : 
                    (<PaginatedPrintbaseList
                        entries={printbases}
                        initialItemsPerPage={25} // Default number of items per page
                        handleListItemClick={handleListItemClick}
                        compareButtonText="Compare"
                        ref={paginationRef}
                    />)}
                {/* <List>
                    {printbases.map(printbase => (
                        <ListItem button onClick={(e) => handleListItemClick(e, printbase.print_exp_id)} key={printbase.print_exp_id}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={printbase.print_exp_id}
                                secondary={`Name: ${printbase.phd_id} || Lab: ${printbase.lab_id} || Date: ${printbase.date} || BioinkID: ${printbase.bioink_id}`}
                            />
                            <ListItemSecondaryAction>
                                <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => handleCompareClick(e, printbase.print_exp_id)}>
                                    {compareButtonText}
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List> */}
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
