import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Typography, MenuItem, Select, FormControl, InputLabel, Box, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import DescriptionIcon from '@material-ui/icons/Description';import Button from '@material-ui/core/Button';

const PaginatedBiobaseList = forwardRef(( {entries, initialItemsPerPage, handleListItemClick}, ref) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const history = useHistory()

  // Calculate the total number of pages based on the selected items per page
  const totalPages = Math.ceil(entries.length / itemsPerPage);

  // Get entries for the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEntries = entries.slice(startIndex, endIndex);

    const [ compareBiobases, setCompareBiobases ] = useState([]);
    const [ compareButton, setCompareButton ] = useState(false);
    const [ compareButtonText, setCompareButtonText ] = useState("Compare this...");
    let compareRef = useRef();

    const handleCompareClick = (e, pId) => {
        compareBiobases.push(pId);
        setCompareButtonText("..With this")
        if (compareBiobases.length === 2) {
            goToCompare();
        }
    };

    const goToCompare = () => {
        localStorage.setItem("compareBiobase1", compareBiobases[0]);
        localStorage.setItem("compareBiobase2", compareBiobases[1]);
        history.push("/biobaseCompare");
    };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when items per page changes
  };

  useImperativeHandle(ref, () => ({
    setThePage() {
      setPage(1);
    }
  }));

  // Helper component for Pagination and Items per Page Selector
  const PaginationControls = () => (
    <Box display="flex" justifyContent="center" alignItems="center" marginTop={2} marginBottom={1}>
      <FormControl variant="outlined" style={{ minWidth: 150 }}>
        <InputLabel>Entries per Page</InputLabel>
        <Select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          label="Items per Page"
        >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={75}>75</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        size="large"
      />
    </Box>
  );

  return (
    <div>
      {/* Top Pagination Controls */}
      <PaginationControls />
      <Paper>
      <List>
            {currentEntries.map(biobase => (
                <ListItem button onClick={(e) => handleListItemClick(e, biobase.bio_exp_id)} key={biobase.bio_exp_id}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={biobase.bio_exp_id}
                        secondary={`Name: ${biobase.phd_id} || Lab: ${biobase.lab_id} || Date: ${biobase.date} || BioinkID: ${biobase.bioink_id}`}
                    />
                    <ListItemSecondaryAction>
                        <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleCompareClick(e, biobase.bio_exp_id)}>
                            {compareButtonText}
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
      </Paper>
      {/* Bottom Pagination Controls */}
      <PaginationControls />
    </div>
  );
});

export default PaginatedBiobaseList;
